<template>
    <v-container fluid class="fill-height pa-0">

        <v-row class="justify-center">
            <v-col cols="12" sm="9" md="6" lg="4">
                <!-- 1. Faktor: Benutzername / Passwort -->
                <v-form v-show="loginStep === 1" v-model="isValid" ref="formCredentials">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Anmeldung
                        </v-card-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="pink"
                                       v-bind="attrs"
                                       v-on="on"
                                       to="/faq"
                                       dark
                                       absolute
                                       top
                                       right
                                       fab>
                                    <v-icon>mdi-comment-question</v-icon>
                                </v-btn>
                            </template>
                            <span>Häufig gestellte Fragen</span>
                        </v-tooltip>
                        <v-card-text class="text-body-1 text--primary">
                            <v-text-field label="IK (9-stellig) / Benutzername" v-model="username" autocomplete="username" required
                                          :rules="[v => !!v || 'Bitte geben Sie Ihren Benutzernamen ein']" />

                            <v-text-field label="Passwort" v-model="password" autocomplete="current-password" required
                                          :type="showPasswort ? 'text' : 'password'"
                                          :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                          :rules="[v => !!v || 'Bitte geben Sie das Passwort ein']"
                                          @click:append="() => (showPasswort = !showPasswort)" />
                            <!-- User message -->
                            <v-alert border="left" :type="userMessageType" v-if="userMessage" class="mt-4">
                                {{ userMessage }}
                            </v-alert>

                        </v-card-text>
                        <v-card-actions>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-btn color="primary" type="submit"
                                           :disabled="!isValid || loading" :loading="loading"
                                           @click.stop.prevent="validateCredentials">

                                        Weiter
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="flex-grow-1">
                                    <v-btn color="primary" type="button"
                                           :disabled="loading" to="/registrierung">

                                        Registrieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="primary" type="button"
                                           class="px-1" text @click="openDialog">

                                        Passwort vergessen?
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <!-- 2.Faktor: OTP -->
                <v-form v-show="loginStep === 2" ref="formOtp">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Anmeldung
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <p>
                                Dieses Konto ist durch Zwei-Faktor-Authentifizierung geschützt.
                                Bitte geben Sie einen von Ihrer Authenticator-App generierten Sicherheitscode ein:
                            </p>
                            <verify-otp :jwt="jwt1FA" @verifyOtpDone="(rst) => { this.jwt2FA = rst; }"
                                        class="d-inline-flex" autofocus />
                        </v-card-text>
                        <v-card-actions>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-btn color="primary" type="button"
                                           @click.stop.prevent="() => loginStep -= 1">

                                        Zurück
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="flex-grow-1">
                                    <v-btn color="primary" type="submit"
                                           @click.stop.prevent="login" :disabled="this.jwt2FA === null">

                                        {{ showIKSelection ? 'Weiter' : 'Anmelden' }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="primary" type="button"
                                           class="px-1" text @click="$refs.authverloren.showInputDialog = true">

                                        Authenticator verloren?
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <!-- Optional: LE-IK-Auswahl -->
                <v-card v-show="loginStep === 3">
                    <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                        Anmeldung
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <p>
                            Bitte geben Sie den gewünschten Leistungserbringer an.<br />
                            Sie haben später zusätzlich die Möglichkeit, diesen über das Menü "Einstellungen" zu wechseln.
                        </p>
                        <switch-ik buttonLabel="Anmelden" @switchDone="loginDone" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Dialog: Passwort vergessen -->
        <passwort-vergessen ref="pwvergessen" />

        <!-- Dialog: Authenticator verloren -->
        <authenticator-verloren ref="authverloren" :jwt="jwt1FA" />

        <!-- Dialog: Cookies -->
        <v-dialog v-model="!$store.state.cookieConsent && $store.state.cookieConsentAsk"
                  persistent>
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-cog-outline</v-icon>
                    Einwilligung für Cookies und ähnliche Technologien
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <p class="text-justify text-hyphenate">
                        Die GFS GmbH und ihre Partner nutzen Technologien wie Cookies und verarbeiten personenbezogene Daten.
                        Da wir Ihre Privatsphäre schätzen und schützen möchten, fragen wir Sie hiermit um Erlaubnis zum Einsatz dieser Technologien.
                        Die entsprechenden Technologien werden nicht zu Werbezwecken genutzt, sondern ausschließlich, um die Webseite
                        technisch bereitzustellen. Sie können in den Einstellungen jederzeit Ihre Entscheidung rückgängig machen bzw. ändern.
                    </p>
                    <router-link to="/datenschutz" target="_blank">Datenschutz</router-link>
                    &nbsp;-&nbsp;
                    <router-link to="/terms" target="_blank">Benutzungsbedingungen</router-link>
                    &nbsp;-&nbsp;
                    <a href="http://www.gfs-web.de/impressum/" target="_blank">Impressum</a>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="$store.commit('updateCookieConsent', true)" text>
                        Zustimmen
                    </v-btn>
                    <v-btn @click="$store.commit('updateCookieConsent', false)" text>
                        Ablehnen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import VerifyOtp from '@/components/VerifyOtp.vue';
    import PasswortVergessen from '@/components/PasswortVergessen.vue';
    import AuthenticatorVerloren from '@/components/AuthenticatorVerloren.vue';
    import SwitchIk from '@/components/SwitchIK.vue';
    import eventBus from '@/main';

    const initialData = function () {
        return {
            showPasswort: false,
            showIKSelection: false,
            loginStep: 1,
            isValid: false,
            username: null,
            password: null,
            jwt1FA: null,
            jwt2FA: null,
            loading: false,
            userMessage: '',
            userMessageType: '',
        };
    };

    export default {
        components: {
            VerifyOtp,
            PasswortVergessen,
            AuthenticatorVerloren,
            SwitchIk,
        },

        data: function () {
            return initialData();
        },

        watch: {
            loginStep: function (newValue, oldValue) {
                if (newValue < oldValue) {
                    this.$refs.formCredentials?.reset();
                    this.$refs.formOtp?.reset();
                }
            },
      
        },

        beforeCreate() {
            this.$http.get('wartung/fetchNotice')
                .then(rsp => {
                    if (rsp.status === 200 && rsp.data) {
                        this.userMessage = rsp.data;
                        this.userMessageType = 'warning';
                    }
                })
                .catch(error => {
                    console.log(error.toJSON());
                });
        },

        activated() {
            this.$refs.formCredentials && this.$refs.formCredentials.reset();
            this.$refs.formOtp && this.$refs.formOtp.reset();
            Object.assign(this.$data, initialData());

            // auto login when oemtoken is provided via query
            if (this.$route.query.oemtoken) {
                this.jwt2FA = this.$route.query.oemtoken;
                this.login();
            }
        },

        methods: {
            async validateCredentials() {


                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('login');

                try {
                    this.loading = true;
                    let response = await this.$http.post('auth/verifylogincredentials', {
                        Token: token,
                        Username: this.username,
                        Password: this.password
                    });

                    if (response.status === 200) {
                        this.jwt1FA = response.data;
                        this.loginStep = 2;
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async login() {
                try {
                    this.loading = true;
                    let response = await this.$http.post('auth/login', {
                        Jwt: this.jwt2FA,
                        CookieConsent: this.$store.state.cookieConsent,
                    });

                    if (response.status === 200) {
                        this.$store.commit('updateCurrentUser', response.data);

                        //Module aufrufen
                        await this.getModule();
 
                        this.loginDone();
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                        this.loginStep = 1;
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            loginDone() {
                eventBus.$emit('loginSuccessful');
                eventBus.$emit('resetLogoutTimer');
                eventBus.$emit('startWidget');
                this.userMessage = "";
                this.$router.push('/');
            },

            openDialog() {
                this.$refs.pwvergessen.showInputDialog = true
            },

            convertDatum(datum) {
                //Zeit aus Datum entfernen
                let dt = datum;
                if (datum) {
                    dt = new Date(datum);
                }
                else {
                    dt = new Date();
                }
                dt.setHours(0, 0, 0, 0);
                return Date.parse(dt);
            },

            findUserRight(i) {
                //Benutzerrecht finden
                let ix = this.$store.state.currentUser.rechte.findIndex(r => r.recht === i);
                return ix !== -1
            },

            async getModule() {
                try {

                    let response = await this.$http.get('module');
                    if (response.status === 200) {
                        var result = response.data;
                        let module = {
                            retaxRead: false,
                            retaxWrite: false,
                            zuzahlungRead: false,
                            zzRueckforderungRead: false,
                            zzRueckforderungWrite: false
                        };

                        //let aktuellDatum = new Date();
                        //aktuellDatum.setHours(0, 0, 0, 0);
                        //let dateNow = Date.parse(aktuellDatum);
                        let dateNow = this.convertDatum("");
                        let dateOpen = Date.parse("12/31/9999");

                        result.forEach(r => {
                           let gbDate = this.convertDatum(r.gbDatum);

                            switch (r.modul) {
                                case 1:
                                    //let modulrights = this.$store.state.currentUser.rechte.findIndex(r => r.recht === 1 || 2 )
                                    if (gbDate === dateOpen) {
                                        module.zuzahlungRead = true && this.findUserRight(3);
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                        module.zzRueckforderungWrite = true && this.findUserRight(8);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                    break;
                                case 2:
                                    if (gbDate === dateOpen) {
                                        module.retaxRead = true && this.findUserRight(1);
                                        module.retaxWrite = true && this.findUserRight(2);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.retaxRead = true && this.findUserRight(1);
                                    break;
                            }
                        });

                        this.$store.commit('updateModule', {
                            retaxRead: module.retaxRead,
                            retaxWrite: module.retaxWrite,
                            zuzahlungRead: module.zuzahlungRead,
                            zzRueckforderungRead: module.zzRueckforderungRead,
                            zzRueckforderungWrite: module.zzRueckforderungWrite
                        });

                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },
         

        }
    };
</script>