<template>
    <!-- 
        Achtung: Kein v-container, denn dieser würde unnötig Platz reservieren. 
        (Dialoge werden an anderer Stelle im DOM eingehangen.)
    -->
    <div>

        <!-- Eingabe Dialog -->
        <v-dialog v-model="showInputDialog">
            <v-card>
                <v-form ref="inputForm">
                    <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                        <v-icon color="primary" large left>$vuetify.icons.accountKey</v-icon>
                        Passwort vergessen?
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-text-field v-model="userInput" label="Bitte geben Sie hier Ihr IK / Ihren Benutzernamen ein"
                                      :rules="[v => !!v || 'Der Benutzername ist erforderlich']"
                                      autocomplete="username" required clearable />

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" type="submit" :disabled="!userInput"
                               @click.stop.prevent="resetPassword">

                            Bestätigen
                        </v-btn>
                        <v-btn color="primary" type="button"
                               @click.stop.prevent="cancel">

                            Abbrechen
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- Dialog Passwort zurücksetzen erfolgreich angetriggert -->
        <v-dialog v-model="showDialogSuccess">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.checkEmail</v-icon>
                    Hilfe ist unterwegs!
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    Sie erhalten in Kürze eine Email mit einem Link, um Ihr Passwort zurückzusetzen.
                    Bitte überprüfen Sie ggf. auch Ihren Spam-Ordner, falls die Email nicht innerhalb weniger Minuten ankommt.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="submit"
                           @click.stop.prevent="showDialogSuccess = false">

                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Fehler -->
        <v-dialog v-model="showDialogFailure">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.error</v-icon>
                    Aktualisierung fehlgeschlagen
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-alert class="mt-4 mb-0" type="error">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button" @click="showDialogFailure = false;">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Captcha fehlgeschlagen -->
        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

    </div>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'

    export default {
        name: 'PasswortVergessen',

        components: {
            CaptchaFailDialog
        },

        data() {
            return {
                userInput: null,
                showInputDialog: false,
                showDialogSuccess: false,
                showDialogCaptchaFailure: false,
                errorMessage: null,
                showDialogFailure: false,
            }
        },
    
        methods: {
            async resetPassword() {

                try {
                    // get captcha token from Google
                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('forgotpassword');

                    // verify by API
                    const rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });
                    if (rsp.status !== 200)
                        throw new Error('Ungültiges Captcha');
                }
                catch {
                    this.showDialogCaptchaFailure = true;
                    return;
                }

                try {
                    let resetRsp = await this.$http.post("auth/passwordreset", { LoginName: this.userInput });
                    if (resetRsp.status !== 200)
                        throw new Error();

                    this.showInputDialog = false;
                    this.userInput = null;
                    this.showDialogSuccess = true;
                    this.$refs.inputForm.reset();
                }
                catch (err) {
                    this.errorMessage = err.response.data.message;
                    this.showDialogFailure = true;
                }
            },

            cancel() {
                this.showInputDialog = false;
                this.userInput = null;
                this.$refs.inputForm.reset();
            },
        },
    }
</script>